<template>
  <div>
    <import-payments-sidebar />
    <!-- Filters -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Exibir</label>
            <v-select v-model="itemsPerPage" dir="ltr" :options="itemsPerPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" @input="search(currentPage)">
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="8">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-primary"
                class="mr-2"
                :disabled="loading"
                @click="search(currentPage)"
              >
                <feather-icon icon="RefreshCwIcon" class="mr-50" />
                Atualizar lista 
              </b-button>
              <b-button
                variant="primary"
                :disabled="loading"
                @click="openImport"
              >
                Nova importação
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontradas importações para esta busca"
        :busy="loading"
      >
        <!-- Column: Mês -->
        <template #cell(month)="data">
          <div class="text-wrap">
            <span class="align-text-top ">
            {{ data.item.month }}/{{ data.item.year }}</span>
          </div>
        </template>
         <!-- Column: Status -->
         <template #cell(status)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.status
            }}</span>
          </div>
        </template>
        <!-- Column: Pagamentos importados  -->
        <template #cell(success_count)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top">{{
              data.item.success_count
            }}</span>
          </div>
        </template>
        <!-- Column: Quantidade de erros -->
        <template #cell(error_count)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top">{{
              data.item.error_count
            }}</span>
          </div>
        </template>
        <!-- Column: Valor importado -->
        <template #cell(gross_amount)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.gross_amount | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Valor líquido -->
        <template #cell(net_amount)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.net_amount | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Responsável -->
        <template #cell(user_name)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top">{{
              data.item.user_name
            }}</span>
          </div>
        </template>
        <!-- Column: Quantidade de correções -->
        <template #cell(total_month_imports)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top ">{{
              data.item.total_month_imports
            }}</span>
          </div>
        </template>
        <!-- Column: Ações -->
        <template #cell(actions)="data">
          <template v-if="downloading.includes(data.item.id)">
            <b-spinner small variant="secondary" />
          </template>
          <template v-else>
            <b-dropdown 
              v-if="data.item.status_id !== importStatus.PENDING &&
                  data.item.status_id !== importStatus.PROCESSING &&
                  data.item.status_id !== importStatus.FAILED"
              variant="link" 
              no-caret 
              right 
              boundary="window">
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item 
                v-if="data.item.status_id !== importStatus.ROLLBACKED"
                @click="openDetails(data.item.year, data.item.month)"
              >
                <feather-icon icon="BarChartIcon" />
                <span class="align-middle ml-50">Dados analíticos</span>
              </b-dropdown-item>
              <b-dropdown-item 
                v-if="data.item.original_file"
                @click="download(data.item.id, data.item.original_file)"
              >
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Baixar arquivo original</span>
              </b-dropdown-item>
              <b-dropdown-item 
                v-if="data.item.errors_file"
                @click="download(data.item.id, data.item.errors_file)"
              >
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Baixar arquivo de erros</span>
              </b-dropdown-item>
              <b-dropdown-item 
                v-if="data.item.status_id == importStatus.COMPLETED || 
                  data.item.status_id == importStatus.PARCIAL"
                @click="rollback(data.item.id)"
              >
                <feather-icon icon="RotateCcwIcon" />
                <span class="align-middle ml-50">Reverter (rollback)</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup,
  BForm,
  BPagination
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ImportPaymentsSidebar from "../components/ImportPaymentsSidebar.vue";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as importStatus from "@/constants/financial_import_status";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BForm,
    BSpinner,
    BFormGroup,
    BPagination,
    ImportPaymentsSidebar,
    vSelect    
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      importStatus,
      loading: false,
      tableColumns: [
        { key: "month", label: "Mês"},
        { key: "status", label: "Status" },
        { key: "success_count", label: "Pagamentos importados" },
        { key: "error_count", label: "Quantidade de erros" },
        { key: "gross_amount", label: "Valor bruto" },
        { key: "net_amount", label: "Valor líquido" },
        { key: "user_name", label: "Responsável" },
        { key: "total_month_imports", label: "Quantidade de correções" },
        { key: "actions", label: "Ações" }
      ],
      currentPage: 1,
      itemsPerPage: 10,
      downloading: [],
    };
  },
  computed: {
    ...mapGetters({
      searchResult: types.PAYMENTS_IMPORTS_LIST,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.searchResult?.data || [];
    },
    totalItems: function () {
      return this.searchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.searchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.searchResult?.to || 0;
    },
  },
  mounted() {
   this.search()
  },
  methods: {
    ...mapActions({
      downloadImportFile: types.DOWNLOAD_IMPORT_FILE,
      searchPaymentImports: types.SEARCH_PAYMENTS_IMPORTS,
      openImportPaymentsSidebar: types.OPEN_IMPORT_PAYMENTS_SIDEBAR,
      rollbackPaymentImport: types.ROLLBACK_PAYMENTS_IMPORT
    }),
    search(currentPage){
      this.loading = true
      this.searchPaymentImports({
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as importações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    download(id, file_name){
      this.downloading.push(id)
      this.downloadImportFile({
        file_name
      })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: `Ocorreu um erro ao fazer download. Entre em contato com o setor de TI.`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.downloading.splice(this.downloading.indexOf(id), 1)
          this.loading = false
        })
    },
    openImport() {
      this.openImportPaymentsSidebar({
        saveAction: this.search
      })
    },
    openDetails(year, month) {
      var routeData = this.$router.resolve({
        name: "payment-import-details",
        params: { year, month },
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    rollback(id) {
      this.$swal({
        title: "Tem certeza?",
        text: `Os pagamentos e comissões referente a essa importação serão removidos. A operação não poderá ser desfeita!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Reverter",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.rollbackPaymentImport(id)
            .then(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: "Operação realizada com sucesso!",
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.search(this.currentPage)
            })
            .catch((err) => {
              if(err.response.status === 405){
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Oops!',
                    text: `Não é possivel efetuar a operação pois já existem comissões pagas referentes a importação. Entre em contato com o setor de TI.`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }else{
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Oops!',
                    text: `Ocorreu um erro realizar a operação. Entre em contato com o setor de TI.`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~flatpickr/dist/plugins/monthSelect/style.css';
</style>
